<template>
  <div style="display: inline;" id="activeCases">
    <v-row>
    <v-col lg="6"
    align="right"
    class="pt-0 pb-0">
    <h2 style="display: inline;">{{ value }}</h2>
    </v-col>
    <!--<label>Active Cases</label>-->
    <v-col lg="6">
    <img style="display: inline;" src="../assets/active-lab-text.png">
    </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: ["value"],
};
</script>

<style scoped>
</style>
<template>
  <div>
    <v-container fill-height class="dashboard-width">
      <!--
      <v-row>
        <v-img
          :src="require('../assets/Casper-V-Covid-logo.png')"
          contain
          height="300"
        />
      </v-row>

      <v-row justify="center">
        <v-col cols="12"
        sm="12"
        lg="3"
        class="ma-lg-5 pt-15 rounded-lg"
        >
         <Last24 v-bind:value="info[2]"></Last24>
          </v-col>
          <v-col cols="12"
          lg="3" 
          sm="12"
          align="center"
          class="ma-lg-5 pt-15 rounded-lg"
          >
        <ActiveCases v-bind:value="info[3]"></ActiveCases>
        </v-col>
        <v-col cols="12"
        lg="3" 
        sm="12"
        align="center"
        class="ma-lg-5 pt-15 rounded-lg"
        >
        <ConfirmedDeaths v-bind:value="info[8]"></ConfirmedDeaths>
         </v-col>
      </v-row>
-->
      <v-row justify="center">
        <v-col cols="12" lg="6" sm="12" align="center" class="rounded-lg">
          <v-img
            :src="require('@/assets/Casper-V-Covid-logo.png')"
            contain
            height="300"
          />
        </v-col>
        <v-col cols="12" lg="6" sm="12" class="ribbon-container">
          <div style="" class="mb-2 pt-0 pb-0 ribbon">
            <a>
              <Last24 v-bind:value="info[2]"></Last24>
            </a>
          </div>
          <div style="" class="mb-2 pt-0 pb-0 ribbon">
            <ActiveCases v-bind:value="info[3]"></ActiveCases>
          </div>
          <div style="" class="mb-2 pt-0 pb-0 ribbon">
            <ConfirmedDeaths v-bind:value="info[8]"></ConfirmedDeaths>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import ActiveCases from "../components/ActiveCases";
import Last24 from "../components/Last24";
import ConfirmedDeaths from "../components/ConfirmedDeaths";
export default {
  components: {
    ActiveCases,
    ConfirmedDeaths,
    Last24,
  },
  computed: {
    info: {
      get() {
        if (this.$store.state.info != null) {
          return this.$store.state.info;
        }
        return [0, 0, 0, 0, 0, 0, 0, 0, 0];
      },
    },
  },
};
</script>

<style>
h2 {
  font-size: 4rem;
  color: #fff;
}

.ribbon {
  background: #000;
}

.dashboard-width {
  width: 650px !important;
}
label {
  color: #fff;
}
.col-12 {
  //text-align: center;
  //height: 300px;
  background-image: ("../assets/Casper-V-Covid-logo.png");
  //background: #000;
  position: relative;
}
</style>
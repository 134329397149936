<template>
  <div style="display: inline;" id="confirmedDeaths">
     <v-row>
    <v-col lg="6"
    align="right"
    class="pt-0 pb-0">
    <h2 style="display: inline;">{{ value }}</h2>
    </v-col>
   <!-- <label>Confirmed Deaths</label>-->
    <v-col lg="6"
    align="left">
   <img style="display: inline;" src="../assets/confirmed-deaths-text.png">
    </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: ["value"],
};
</script>

<style scoped>
</style>
import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
import Vuex from 'vuex'
import vuetify from './plugins/vuetify';

Vue.config.productionTip = false
Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    info: null
  },
  mutations: {
    setInfo(state, value) {
      state.info = value;
    }
  }
})

new Vue({
  render: h => h(App),
  store,
  vuetify,

  mounted() {
    axios.get("https://script.google.com/macros/s/AKfycbwoPP0JLZb1dbTGXN9KTCS-TtHXG7QHLcYsTz6XaSI7ev_2qQQ/exec").then(response => (store.commit('setInfo', response.data[0])))
  }
}).$mount('#app')
